import { HOME, ABOUT, CONTACT, MORE } from "../../utils/routes.js"

export const ROUTES_ARR = [
  {
    name: "ראשי",
    route: HOME,
  },
  {
    name: "מידע",
    route: ABOUT,
  },
  {
    name: "צרו קשר",
    route: CONTACT,
  },
  {
    name: "חומר למחשבה",
    route: MORE,
  },
]
